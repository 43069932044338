* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  font-size: 90%;
  @include bp('papa-bear') {
    font-size: 90%;
  }
  @include bp('papa-bear') {
    font-size: 100%;
  }
}

html {
  background : $bodycolor;
  -webkit-overflow-scrolling : touch;
  -webkit-tap-highlight-color : lighten($basecolor, 75%);
  -webkit-text-size-adjust : 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  font-family: $basefont;
  line-height: $baselineheight;
  color: $textcolor;
}

body{
  .content{
    margin: 0 auto;
  }
}

.container {
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;
  max-width: 70rem;

  @include bp('mama-bear') {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

section {
  position: relative;
}

section#brand {
  position: relative;
  margin-top: 4rem;

  @include bp('papa-bear') {
    margin-top: 10rem;
  }

  article{
    padding-top: 4em;
    padding-bottom: 4em;
  }
}

#brand:after {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 30px solid $bodycolor;
  position: absolute;
  bottom:-30px;
  display: block;
  content: "";
  margin-left: auto;
  margin-right: auto;
  left:calc(50% - 40px);
  z-index: $low-index;
}

article{
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.green{
    background: $altbodycolor;
    color: #fff;
    border-top: 5px solid $border-dark;
}
.white{
  /*border-top: 10px solid $border-light;*/
}

p#logo-large{
  text-align: center;
  img{
    max-width: 700px;
    width: 100%;
  }
}

aside#contactDetails{
  position: relative;
  margin-top: 2rem;

  a, h3{
    color: #fff;
  }

  span {
    position: absolute;
    top: 0;
    left: -5rem;
    font-size: 5rem;
    color: $darkgrey;

  }

}

#result h3{
    color: #575756;
        font-size: 1.3em;
}

#top {
  background: #ff0000;
  position: absolute;
  top:4rem;
  height: 4rem;
  z-index: 100000;
}

.anchor {
  position: absolute;
  background: #ff0000;
  display: block;
  top: -5rem;
}
