.main {

}

/**
 * Team Profiles
 */
@include bp('papa-bear') {
  section#section-team {
    .team-profiles {
    }
  }
}

/**
 * Testimonials
 */
section#section-testimonials {
  @include bp('papa-bear') {
    .columns {
      display: flex;
      justify-content: space-between;

      .column6 {
        flex: 0 0 47%;
      }
    }
  }
  article{
    blockquote{
      border-left: 10px solid $midGreen;
      padding-left: 1.5rem;
      /*@include box-sizing(border-box);*/
      margin: 0 0 2rem 0;
      p{
        margin: 0 0 5px 0;
        &.author{
          font-style: italic;
          font-size: 16px;
          margin: 0;
          text-align: right;
        }
      }
    }
  }
}

/**
 * Contact form
 */
.section-getintouch {
  display: flex;
}
#contactForm{
    p{
      clear:both;
    }
    label{
        font-family: $altfont;
        color: $white;
        font-size: 1.3em;
    }
    label.error{
        color: #575756;
        font-size: 1.1em;
    }
    input[type=text]{
        border-radius: 3px;
        padding: 10px 10px 10px 50px;
        line-height: 1.2em;
        font-size: 1.2em;
        border: none;
        background-repeat: no-repeat;
        background-position: 10px 50%;
        //@include transition-property(background-color)
        transition: background-color $duration ease-in-out;
        &:focus{
            background-color: #d4f3c0;
        }
        /*&#yourtel{
            background-image: url('../img/icon_tel.png');

        }
        &#youremail{
            background-image: url('../img/icon_email.png');
        }
        &#yourname{
            background-image: url('../img/icon_name.png');
        }*/
    }
    textarea{
        border-radius: 3px;
        height: 12em;
        font-size: 1.2em;
        background-image: url('../img/icon_message.png');
        background-repeat: no-repeat;
        background-position: 10px 10px;
        border: none;
        padding: 10px 10px 10px 50px;
        transition: background-color $duration ease-in-out;
        &:focus{
            background-color: #d4f3c0;
        }
    }

    input[type=submit]{
        padding: 10px 50px;
        border-radius: 3px;
        background: $mainGrey;
        color: #fff;
        font-weight: 700;
        border: none;
        font-size: 1.2em;
        font-family: $altfont;
        clear: both;
        transition: all $duration ease-in-out;
        &:hover, &:active{
            background: #4c4c4c;
        }
    }
}

/**
 * SEction Get in touch
 */


.section-getintouch {
  display: flex;
  flex-direction: column;
  @include bp('papa-bear') {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}


form{
  flex: 0 0 100%;
  @include bp('mama-bear') {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-col{
      flex: 0 0 47%;
    }
  }
  @include bp('papa-bear') {
    flex: 0 0 60%;
    .submit-container {
      flex: 0 0 100%;
    }
  }
}



aside{
  flex: 0 0 60%;
  @include bp('papa-bear') {
    flex: 0 0 30%;
    margin-left: 4rem;
  }
}

/**
 * CTA
 */
 .cta {
  text-align: center;
 }

 .contactDetails {

    a {
      text-decoration: none;
    }

    span {
      display: inline-block;
      width: 30px;
    }
 }

 .social-links {
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    padding: 0 0.5rem;
  }

  a {
    display: block;
    padding: 0.5rem;
    background: $mainGrey;
    color: #fff;
    border-radius: 3px;
    transition: all $duration ease-in-out;

    &:hover, &:active {
      background: darken($mainGrey, 10%);
    }
  }

  span {
    font-size: 2rem;
    display: block;
  }
}