/*.header {

}
.logo {
  margin: -58px 0 0 -100px;
  @include position(absolute, 50% 0 0 50%);
  img {

  }
}*/







/*header{
    position: fixed;
    margin-top: -80px;
    height: 80px;

    hgroup{
        margin-top: 10px;
        #logo{
            img{
                display: block;
            }
        }
    }
}*/

header {
  position: absolute;
  top: 0;
  width: 100%;

  hgroup {
    #logo, h1 {
      display: none;
    }
  }
}

@include bp('papa-bear') {
  header {
    width: 100%;
    z-index: 1000;
    background: $mainGrey;
    min-height: 60px;
    /*-webkit-transition: top 0.2s ease-in-out;
    @include transition(background-color, 2s, linear, 0.5s);*/
    transition: top 0.2s ease-in-out;
    transition: background 0.3s ease-in-out;
    &.is-visible{
      margin-top: 0px;
      background: $mainGrey;
    }
    hgroup{
      display: flex;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      #logo{
        display: block;
        flex: 0 0 250px;
        margin-top: 0;

        a {
          display: block;
          padding: 0 0.5rem;
        }
        img{
          //background: #ffffff;
          //padding: 55px 10px 10px 10px;
          //@include rotate(355deg);
          //position: absolute;
          //margin-top: -35px;
           //@include box-shadow(#666 0px 2px 1px);
           width: 250px;
           /*display: none;*/
        }
      }
      h1{
          display: none;
      }
    }
  }
}

.header_contact {
  background: $mainGrey;
  @include bp('papa-bear') {
    height: 4rem;
    background: darken($mainGrey, 10%);
  }

  ul {
    list-style: none;
    display: flex;
    @include bp-max($bp-baby-bear) {
      flex-direction: column;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    @include bp('baby-bear') {
      justify-content: space-between;
    }

    li {
      color: white;
      @include bp('baby-bear') {
        padding: 1rem 0.5rem;
      }
    }

    a{
      color: white;
      text-decoration: none;
      transition: all $duration ease-in-out;

      &:hover {
        color: $altbodycolor;
      }
    }
  }
}