@mixin position ($position: relative, $coordinates: 0 0 0 0) {
    @if type-of($position) == list {
        $coordinates: $position;
        $position: relative;
    }

    $top: nth($coordinates, 1);
    $right: nth($coordinates, 2);
    $bottom: nth($coordinates, 3);
    $left: nth($coordinates, 4);

    position: $position;

    @if $top == auto {
        top: $top;
    }
    @else if not(unitless($top)) {
        top: $top;
    }
    @if $right == auto {
        right: $right;
    }
    @else if not(unitless($right)) {
        right: $right;
    }
    @if $bottom == auto {
        bottom: $bottom;
    }
    @else if not(unitless($bottom)) {
        bottom: $bottom;
    }
    @if $left == auto {
        left: $left;
    }
    @else if not(unitless($left)) {
        left: $left;
    }
}

@mixin font-size($font-size: 16){
font-size : #{$font-size / 10}rem; }

/*------------------------------------*\
    #BREAKPOINT MEDIA QUERY MIXIN
\*------------------------------------*/

@mixin bp($point) {
  @if $point == "teddy-bear" {
    @media (min-width: $bp-teddy-bear) { @content; }
  }
    @if $point == "baby-bear" {
    @media (min-width: $bp-baby-bear) { @content; }
  }
  @else if $point == "mama-bear" {
    @media (min-width: $bp-mama-bear) { @content; }
  }
  @else if $point == "papa-bear" {
    @media (min-width: $bp-papa-bear)  { @content; }
  }
  @else if $point == "grizzly-bear" {
    @media (min-width: $bp-grizzly-bear)  { @content; }
  }

  @if $point == "sparkly-bear" {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { @content; }
  }
}

// A media query starting at $point1 and end JUST BEFORE $point2
@mixin bp-range($point1, $point2) {
  @media (min-width: $point1) and (max-width: $point2 - 0.0001em)  { @content; }
}

// A media query ending JUST BEFORE $point2
@mixin bp-max($point2) {
  @media (max-width: $point2 - 0.0001em)  { @content; }
}

/*------------------------------------*\
    #TEXT DECORATION
\*------------------------------------*/

@mixin text-decoration() {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

@mixin text-decoration-alt() {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

@mixin text-decoration-plain() {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}