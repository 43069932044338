/*------------------------------------*\
    NAVIGATION FOR SMALLER DEVICES
\*------------------------------------*/
 @include bp-max($bp-papa-bear) {
  .menu-close {
    position: absolute;
    display: block;
    top: 6.5rem;
    left: -3.8rem;
    padding: 1rem;
    box-sizing: border-box;
    font-size: 2rem;
    text-align: right;
    @include text-decoration-plain();
    background: darken($mainGrey, 10%);
    color: white;
    border-radius: 5px 0 0 5px;
    transition: all $duration ease-in-out;
    z-index: 1;

    &:hover {
      text-decoration: none;
    }

    span {
      display: none;
    }
  }

  #menu {
    padding-top: 2rem;
    background: darken($mainGrey, 10%);
    @include bp('papa-bear') {
      background: $mainGrey;
    }

    position: fixed;
    top:0;
    bottom:0;
    right:0;
    height: 100%;
    z-index: 2000;
    transition: all  $duration;
    width: 260px;
    transform: translateX(260px);

    ul {
      list-style: none;
      margin:0;
      padding:0;
      /*margin-top:4em;*/
      transition: all $duration ease-in-out;

      li {
        position: relative;
        list-style: none;
        display: inline-block;
        width: 100%;

        a {
          transition: all $duration ease-in-out;
          color: white;
          text-decoration: none;
          padding: 0.5rem 1.5rem;
          font-size: 1.5rem;

          line-height: 1.3em;
          display: block;

          @include bp("mama-bear") {
            padding: 0.875 2rem;
          }

          &:hover, &:active {
            /*background: $colour-secondary;
            color: $colour-primary;*/
          }
        }
      }
    }
  }

  .active-nav {
    #menu {
      transform: translateX(0);
    }
  }
}

@include bp-range($bp-baby-bear, $bp-papa-bear) {
  #menu {
    width: 360px;
    transform: translateX(360px);
  }
}

@include bp('papa-bear') {

  .menu-close {
    display: none;
  }

  nav{
    position: relative;
    flex: 1;
    //@include span-columns(8 omega,12);
    /*width: 75%;
    right:20px;
    top: -10px;*/
    clear: none;
    margin-top:0;

    #menu{
      padding:0;

      ul{
        display: flex;
        margin: 0;
        padding: 0;
        border-top: none;
        z-index: 20000;
        //@include span-columns(8 ,12);
        float: right;
        margin-bottom: 0;
        background: none;

        /*width: auto;*/
        list-style: none;

        li{
          /*@include horizontal-list-item(0, left);*/
          width: auto;
          padding:5px 0.5rem;
          border-bottom: none;
          border-left: none;

          a{
            position: relative;
            @include text-decoration-plain();
            padding: 0.5em 0.8em;
            font-size: 1.2rem;
            border-radius: 10px;
            background: none;
            //color: $highlight;
            color: #fff;
            font-weight: 700;

            &:after{
              background: #fff;
              height: 2px;
              width: 0%;
              content: '';
              position: absolute;
              bottom: 5px;
              left:50%;
              transition: all $duration ease-in-out;
            }

            &:hover{
              text-decoration: none;

              &:after{
                width: 100%;
                left:0;
              }
            }

            &.linkcontact {
              border-radius: 3px;
              background: $altbodycolor;
              color: #fff;
              margin-top: 15px;
              transition: all $duration ease-in-out;

              &:hover{
                background: $highlight2;
                text-decoration: none;
              }

              &:after{
                display: none;
              }
            }

          }

          &:nth-child(odd){
            a{
              border-right: none;
            }
          }

        }
      }
    }
  }
}

@include bp('grizzly-bear') {
  nav #menu ul li a{
    font-size: 1.4rem;
  }
}

.js #nav {
  clip: rect(0 0 0 0);
  max-height: 0;
  //position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
}