h1, h2, h3, h4 {
margin : 0;
font-family : $altfont;
font-weight : $baseheadingfontweight;
color : $baseheadingfontcolor;
text-rendering : optimizelegibility; }

h1 small,
h2 small {
font-weight : normal;
color : $lightgrey; }

h1,
.h1 {
margin-bottom : $baselineheight / 2;
@include font-size(32);
line-height : 1.2; }

h2,
.h2 {
margin-bottom : $baselineheight / 2;
@include font-size(30);
line-height : 1.2;
color: $midGreen;
}

h2.highlight{
  @include font-size(30);
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
section.green{
    h2{
        color: #fff;
    }
}

h3,
.h3 {
margin-bottom : $baselineheight / 1.5;
@include font-size(14);
line-height : 1.3;
color: $highlight;}

h4,
.h4 {
margin-bottom : $baselineheight;
@include font-size(18);
line-height : 1.25; }

h5,
.h5 {
@include font-size(16);
margin-bottom : $baselineheight; }

h6,
.h6 {
margin-bottom : $baselineheight;
@include font-size(14);
font-weight : normal;
letter-spacing : 1px;
text-transform : uppercase; }

// header

.header h1,
.header h2,
.header h3 {
float : left;
margin-right : $gutterwidth;



a {
display : block;
position : relative;
top : 6px;
float : right;
padding : 4px 12px;
@include font-size(14);
background-color : lighten($basecolor, 75%); }

&:hover {
background-color : lighten($basecolor, 75%); }
}

.header .a-rss,
.header .a-rss:hover {
float : left;
width : 16px;
height : 16px;
padding : 0;
background : transparent url(../img/a-rss.png) no-repeat 0 0;

 }

// TYPOGRAPHY ====================================================

p,
ol,
ul,
dl,
address {
margin-bottom : $baselineheight;
/*@include font-size(10);*/
font-size: 1.2rem;
line-height : $baselineheight; }

small {
@include font-size(14); }

ul,
ol {
margin : 0 0 $baselineheight #{-$gutterwidth};
padding : 0 0 0 $gutterwidth; }

li ul,
li ol {
margin : 0;
@include font-size(16); }

// blockquote

blockquote  {
margin : 0 0 $baselineheight #{-$gutterwidth};
padding-left : $gutterwidth;
border-left : 2px solid $bordercolor;
font-family : $altfont;
font-style : normal; }

q {
quotes : none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
content : '';
content : none; }

cite {
font-style : normal; }

// definition lists

dl,
dd {
margin-bottom : $baselineheight;  }

dt {
font-weight : bold; }

.dl-horizontal {

dt {
float : left;
clear : left;
width : 20.25%;
text-align : right; }

dd {
margin-left : 22.78%; }
}

// text-level

abbr[title] {
border-bottom : 1px dotted $bordercolor;
cursor : help; }

b, strong {
font-weight : bold; }

dfn {
font-style : italic; }

ins {
background-color : lighten($basecolor, 75%);
color : $textcolor;
text-decoration : none; }

mark {
background-color : lighten($basecolor, 75%);
color : $textcolor;
font-style : italic;
font-weight : bold; }

pre,
code,
kbd,
samp {
font-family : $codefont;
@include font-size(14);
line-height : $baselineheight; }

pre {
white-space : pre;
white-space : pre-wrap;
word-wrap : break-word; }

sub,
sup {
position : relative;
@include font-size(12);
line-height : 0;
vertical-align : baselineheight; }

sup {
top : -0.5em; }

sub {
bottom : -0.25em; }

ul.contactDetails{
  li{
    font-size: 18px;
    margin: 0 0 10px 0;
    list-style: none;
    &.linkedin{
      /*background: url('../img/icon-linkedin.png') no-repeat;*/
      display: block;
      line-height: 32px;
      padding: 0 0 0 40px;
    }
    &.tel{
      /*background: url('../img/icon_tel.png') no-repeat;*/
      display: block;
      line-height: 30px;
      padding: 0 0 0 40px;
    }
    &.email{
      /*background: url('../img/icon_email2.png') no-repeat;*/
      display: block;
      line-height: 26px;
      padding: 0 0 0 40px;
    }
    a{
      color: $midGreen;
    }
  }

}
