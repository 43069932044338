.header_contact {
  ul {
    li:first-child {
      animation: fromLeft 1s ease-out;
    }

    @include bp-max($bp-baby-bear) {
      li:last-child {
        animation: fromLeft 2s ease-out;
      }
    }

    @include bp('baby-bear') {
      li:last-child {
        animation: fromRight 1s ease-out;
      }
    }
  }
}

header hgroup #logo {
  opacity: 0;
  animation: fromLeft 1s ease-out 0.5s forwards;
}

#menu ul {
  opacity: 0;
  animation: fromRight 1s ease-out 0.5s forwards;
}

@include bp('papa-bear') {
  header {
    /*transition: all $duration ease-in-out;*/
    &.pos-fixed {
      position: fixed;
      transform: translateY(-4rem);
    }
  }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fromTop {
    from { transform: translateY(-2rem); opacity: 0; }
    to   { transform: translateY(0); opacity: 1; }
}

@keyframes fromLeft {
    from { transform: translateX(-2rem); opacity: 0; }
    to   { transform: translateX(0); opacity: 1; }
}

@keyframes fromRight {
    from { transform: translateX(2rem); opacity: 0; }
    to   { transform: translateX(0); opacity: 1; }
}

#logo-large {
  opacity: 0;
  animation: fromTop 1s ease-out 1s forwards;
}

.social-links {
  opacity: 0;
  animation: fromTop 1s ease-out 1.25s forwards;
}

.cta {
  opacity: 0;
  animation: fromTop 1s ease-out 1.5s forwards;
}

#section-service h2,
#section-service p,
#section-team h2,
#section-team p,
#section-team ul,
#section-testimonials h2,
#section-testimonials h3,
#section-testimonials blockquote {
  transition: all 0.5s ease-in-out;
  transform: translateY(1rem);
  opacity: 0;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

#section-messaging h2 {
  transition: all 0.7s ease-in-out;
  transform: scale(1);
  opacity: 0;
  &.active {
    transform: scale(1);
    opacity: 1;
  }
}