
.icon-phone:before { content: '\e800'; } /* '' */
.icon-mail:before { content: '\e801'; } /* '' */
.icon-user:before { content: '\e802'; } /* '' */
.icon-chat:before { content: '\e803'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */