footer{
  background: $mainGrey;
  #footerInner{
    color: #fff;
    line-height: 3em;
    text-align: center;
    @include bp('mama-bear') {
      text-align: left;
    }
  }
}