.btn {
  border-radius: 3px;
  background: $altbodycolor;
  color: #fff;
  margin-top: 15px;
  padding-top: 10px;
  font-size: 1.4rem;
  transition: all 0.3s ease-in-out;
  &:hover{
    background: $highlight2;
    text-decoration: none;
  }
}

.btn,
.btn-secondary,
.btn-reverse,
.btn-contact,
.btn-small {
  @include text-decoration-plain();
  display: inline-block;
  padding: 0.5em 0.8em;
  background-color: $altbodycolor;
  border: none;
  color: white;
  text-decoration: none;
  transition: background $duration, border $duration, color $duration;
  font-weight: bold;
  border-radius: 3px;

  .icon-right {
    margin-left: 2em;
    font-size: 90%;
    color: white;
    transition: color $duration;
  }

  &:hover,
  &:focus,
  &.active {
    background: $highlight2;
    color: white;

    .icon-right {
      color: white;
    }
  }
}